body {
}
body.not-front {
  background: url(../images/snow-white.jpg) repeat;
}

.outer-wrapper {
  @include media($mobile) {
    // @include span-columns(2);
    // @include omega();
    padding-left: 15px;
    padding-right: 15px;
  }
}

footer#footer_one {
  background-color: #FFF;
  .outer-wrapper {
    padding-bottom: 50px;
    padding-top: 50px;
  }

}

footer#footer_two {
  background-color: #e7e5e5;

  > .region {
    background: url(../images/dog-whip-2.png) no-repeat;
    background-position-x: center;
    background-position-y: 90%;

    @include outer-container;
    > .block {
      @include span-columns(4);
      @include media($mobile) {
        @include span-columns(2);
      }
    }
  }
}
aside#sidebar-left {
  padding-left: 20px;
  @include media($mobile) {
    padding-left: 0;
  }
}

aside#sidebar-left,
aside#sidebar-right {
  margin-top: 20px;
  margin-bottom: 20px;
}
section#content {
  margin-top: 45px;
  margin-bottom: 100px;
  padding-left: 20px;
  padding-right: 40px;

  @include media($mobile) {
    margin-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}

section#content-fluid {
  //max-width: none !important;
  @include outer-container;
  > .region {
    //@include clearfix;
    @include fill-parent();
    @include row(table);
  }
}

body.has-sidebar-right {
  main {
    background-color: #FFF;
    padding: 40px 60px 20px 60px;
    @include row();
    section#content {
      @include span-columns(8);
    }
    aside#sidebar-right {
      @include span-columns(4);
    }
  }

  #content-bottom {
    background-color: #f1f1f1;
    padding: 40px 60px;
    .region {
      > .block {
        @include span-columns(4);
      }
    }
  }
}

body.has-sidebar-left {
  main {
    aside#sidebar-left {
      @include span-columns(3);
      @include media($mobile) {
        @include span-columns(2);
      }
    }
    section#content {
      @include span-columns(9);
      @include media($mobile) {
        @include span-columns(2);
      }
    }
  }
}

