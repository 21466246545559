// @import url(https://fonts.googleapis.com/css?family=Oswald);
// @import url(https://fonts.googleapis.com/css?family=Open+Sans);
// @import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900);
//@import url('https://fonts.googleapis.com/css?family=Anton');


$heading_font: 'Lato', sans-serif;
$body_font: 'Lato', sans-serif;


body {
  @include font-feature-settings("kern", "liga", "pnum");
  -webkit-font-smoothing: antialiased;
  font-family: $body_font;
  font-size: 16px;
  line-height: 1.3em;
  color: #2b2b2b;
}
h1, h2, h3, h4, h5, h6 {
  line-height: 1.1em;
  margin-top: 0;
  font-family: $heading_font;
  font-weight: 900;
  letter-spacing: 0.05em;
}
h1 {
  font-size: 36px;
  text-transform: uppercase;
  @include media($mobile) {
    font-size: 24px;
  }
}

h2 {
  font-size: 30px;
  font-weight: 900;
}

h3 {
  font-size: 24px;
}

h4 {
  color: #1e647b;
  font-size: 18px;
  a {
    color: inherit;
  }
}

p {
  margin: 0 0 10px;
}

a {
  color: #1e647b;
  text-decoration: none;
  transition: color 0.1s linear;

  &:active,
  &:focus,
  &:hover {
    color: darken(#1e647b, 15%);
  }

  &:active,
  &:focus {
    outline: none;
  }
}

hr {
  border-bottom: 1px solid #dedede;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: 10px 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto;
}

.button {

}

.date-display-range,
span.date-display-single {
  color: #010030;
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}
a.views-more-link {
  color: #b19714;
}

ul, ol {
  li {
    margin-bottom: 5px;
  }
}
