.view-photo-galleries {
  .view-content {
    @include outer-container;
    .views-row {
      @include span-columns(3);
      @include omega(4n);

    }
  }
}

.view-latest-news-releases {
  .view-content {
    .views-row {
      margin-bottom: 10px;

      @include span-columns(4 of 8);
      @include omega();

      @include media($mobile) {
        @include span-columns(2);
        @include omega();
      }
      .views-field-field-image {
        margin-bottom: 15px;
        a {
          display: block;
        }
      }
      .meta {
        padding: 0 15px;
        .views-field-title {
          h4 {
            margin-bottom: 0;
            a {
              color: #07417d;
            }
          }
        }
        .views-field-field-news-date {
          margin-bottom: 5px;
        }
      }
    }
    .views-row-odd {
      padding-right: 20px;
      @include media($mobile) {
        padding-right: 0;
      }
    }
    .views-row-even {
      padding-left: 20px;
      @include media($mobile) {
        padding-left: 0;
      }
    }
  }
}

.view-latest-news-releases-page {
  .view-content {
    .views-row {
      margin-bottom: 20px;

      .views-field-field-image {
        float: left;
      }

      .meta {
        margin-left: 390px;

        .views-field-title {
          h4 {
            margin-bottom: 0;
          }
        }
        .views-field-field-news-date {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.view-photo-block {
  margin-right: 20px;
  @include media($mobile) {
    margin-right: 0;
  }
  .view-content {
    .views-row {
      margin-bottom: 10px;
      @include span-columns(6);
      @include omega(2n);
    }
  }
}

.view-press-coverage {
  .view-content {
    .views-row {
      margin-bottom: 20px;
    }
  }
}

.view-press-coverage-block {
  .view-content {
    .views-row {
      margin-bottom: 10px;

      .views-field-title {
        a {
          margin-bottom: 0px;
          color: #FFF;
        }
      }
      .views-field-field-press-date {
        .date-display-single {
          color: #ffd70c;
        }
      }
    }
  }
}

.view-partners {
  .view-content {
    .views-row {
      margin-bottom: 10px;
      .views-field-field-partner-logo {
        a {
          display: block;
          text-align: center;
        }
      }
    }
  }
}

.view-sponsors {
  .view-content {
    .views-row {
      min-height: 80px;
      margin-bottom: 15px;
      @include span-columns(6);
      @include omega(2n);

      @include media($mobile) {
        @include span-columns(2);
      }
    }
  }
}

.view-productions,
.view-stage-productions {
  .view-content {

    .views-row {
      margin-bottom: 20px;
      @include clearfix;
      .views-field-field-pro-thumbnail,
      .views-field-field-stage-thumbnail {
        float: left;
        @include media($mobile) {
          float: none;
          @include span-columns(2);
        }
      }
      .views-field-title {
        h4 {
          margin-bottom: 10px;
        }
      }
      .views-field-field-prod-year {
        float: right;
        span {
          display: inline-block;
          border: 1px solid #CACAC5;
          padding: 5px 10px;
          border-radius: 20px;
        }
      }
      .views-field-field-prod-type {
        color: #868181;
        margin-bottom: 5px;
        font-style: italic;
      }
      .wrapper {
        margin-left: 380px;
        padding: 20px;
        @include media($mobile) {
          margin-left: 0;
          @include span-columns(2);
        }
      }
    }
    .views-row:hover {
      background-color: #f9f9f9;
    }
  }
}
