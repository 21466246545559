table {
  @include font-feature-settings("kern", "liga", "tnum");
  border-collapse: collapse;
  margin: 10px 0;
  table-layout: fixed;
  width: 100%;
  background-color: #f2f0f1;
  border: none;
}

th {
  border-bottom: 1px solid darken(#333, 15%);
  font-weight: 600;
  padding: 10px 0;
  text-align: left;
}

td {
  border-bottom: 1px solid #ededed;
  padding: 20px;
}

tr,
td,
th {
  vertical-align: middle;
  border-bottom: 1px solid #dcd9c8;
}

tr {

}

table {
  strong, b {
    color: #0fb6fd;
  }
}

table#table-member {
  tbody {
    border: none;
    tr {
      td:first-child {
        width: 140px;
      }
    }
  }
}

#table-member {
  @extend .table-stacked;
  tbody {
    tr {
      td:first-child {
        width: auto !important;
      }
      td {
        padding-left: 15px !important;
      }
    }
  }
}

// Responsible table as stack
@media only screen and (max-width: 768px) {
  .table-stacked {
    display: block;

    /* Force table to not be like tables anymore */
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      //border: 1px solid #ccc;
    }

    td {
      /* Behave like a "row" */
      border: none;
      //border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      white-space: normal;
      text-align:left;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align:left;
      font-weight: bold;
    }

    /*
    Label the data
    */
    td:before { content: attr(data-title); }
  }
}
