.button {
  display: inline-block;
  padding: 7px 25px;
  border-radius: 26px;
  color: #063449;
  background-color: #f5f7ee;

  &:hover, &:focus {
    color: #06255b;
    background-color: darken(#f5f7ee, 5%);
    outline: none;
  }
}
