.bx-wrapper {
  .bx-viewport {
    box-shadow: none;
    border: none;
    left: 0;
    ul.bxslider {
      margin: 0;
      padding: 0;
      li {
        .views-field-field-slider-image {
          picture {
            img {
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
  }
  .bx-controls {
    a {
      height: 37px;
      width: 37px;
      margin-top: -18px;
      background: url(../images/next-prev.png) no-repeat;

      &.bx-prev {
        background-position-x: left;
      }
      &.bx-next {
        background-position-x: right;
      }
    }
  }
}
