
.block-search {
  float: right;
  @include media($tablet) {
    margin-right: 65px;
  }
  @include media($mobile) {
    float: none;
  }

  .content {
    form {

      .container-inline {
        @include clearfix;
        .form-item {
          display: block;
          float: left;
          margin-bottom: 0;
          margin-top: 0;
          @include media($mobile) {
            width: calc(100% - 40px);
          }
          input[type="text"] {
            border: none;
            background-color: #1097d0;
            color: #FFF;
            padding: 3px 10px;
            height: 40px;
            @include media($mobile) {
              width: 100%;
            }

            &:focus {
              border-bottom-color: #FFF;
              outline: none;
            }
          }
        }
        .form-actions {
          background-color: #06648b;
          display: block;
          float: left;
          input[type="submit"] {
            background-image: url(../images/mag-glass-white.png);
            background-color: transparent;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: 10px;

            font-size: 0;
            height: 40px;
            width: 40px;
            border: none;

          }
        }
      }
    }
  }
}
.block-locale {
  text-align: right;
  float: right;

  @include media($mobile) {
    padding-left: 5px;
    margin-left: -15px;

  }
  ul.language-switcher-locale-url {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 6px;
    margin-bottom: 0;
    li {
      display: inline;

      &.iu {
        a {
          font-size: 0.8em;
        }
      }
      a {
        color: #FFF !important;
        //padding: 6px 8px 7px 8px;
        padding: 0 8px;
        display: inline-block;
        font-size: 0.85em;
        line-height: 27px;
      }
    }
    li.active {
      display: none;
    }
  }
}

.block-sponsors {
  h2 {
    text-align: center;
  }
  .content {
    text-align: center;
    img {
      margin-right: 60px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.block-highlight {
  position: relative;
  @include span-columns(4);
  @include media($mobile) {
    display: block !important;
    @include span-columns(2);
    @include omega();
  }
  .content {
    .view {
      .view-content {
        .views-row {
          position: relative;
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            content: "";
            opacity: 0.4;
            box-shadow: inset 0 0 8px 0 #000;
            //@include linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
            //@include linear-gradient(180deg, #064b8d, #6a216a);
            @include transition(all 0.2s ease-in-out);
            @include linear-gradient(180deg, rgba(0, 0, 0, .8), rgba(0, 0, 0, .5));
          }

          .views-field-field-image,
          .views-field-field-album-cover {
            img {
              display: block;
              width: 100%;
            }
          }

          .views-field-title {
            position: absolute;
            top: 30px;
            padding: 0 20px;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 10;

            @include media($mobile) {
              top: 50px;
              padding: 0 10px;
            }
            h2 {
              font-size: 24px;
              text-transform: uppercase;
              display: inline-block;
              //width: 370px;

              @include media($mobile) {
                font-size: 18px;
              }
              a {
                color: #FFF;
              }
            }
          }

          .views-field-view-node {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 30px;
            text-align: center;
            z-index: 10;
            a {
              color: #FFF;
            }
          }

        }
      }
    }
  }
}

.block-contact {
  padding-top: 30px;

  @include media($mobile) {
    padding-left: 15px;
    padding-right: 15px;
  }
  h2 {
    margin-top: 0;
    color: #d4b726;
    text-transform: uppercase;
  }
  .content {
    color: #0d0f1e;
    a {
      color: #0d0f1e;
    }
  }
}

.block-socials {
  padding-top: 30px;
  @include media($mobile) {
    padding-left: 15px;
    padding-right: 15px;
  }
  h2 {
    margin-top: 0;
    color: #d4b726;
    text-transform: uppercase;
  }
  a.link-facebook {
    display: inline-block;
    font-size: 0;
    top: -25px;
    position: relative;
    margin-left: 15px;
    height: 55px;
    width: 55px;
    background: url(../images/facebook.png);

  }
}

.block-donations {
  background-color: #d7d7d7;
  padding-top: 30px;
  padding-bottom: 50px;
  h2 {
    text-align: center;
  }
  .content {
    text-align: center;
  }
}

.block-content-bottom {
  @include span-columns(4);

  @include media($mobile) {
    @include span-columns(2);
  }
  h2 {
    text-transform: uppercase;
    color: #FFF;
  }
}
.block-press-coverage,
.block-map,
#block-views-photo-block-block {
  @extend .block-content-bottom;
  @include media($mobile){
    margin-right: 0;
    margin-bottom: 30px;
  }
}

#block-views-latest-news-releases-block {
  padding: 30px 0;
  @include span-columns(8);
  @include media($mobile) {
    @include span-columns(2);
  }
  h2 {
    color: #128abc;
    text-transform: uppercase;
  }
}
.block-shop {
  @include span-columns(4);

  @include media($mobile) {
    @include span-columns(2);
  }
  h2 {
    color: #0d5371;
    padding: 30px 50px;
    background: #f1da64;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 30px;
    &:after {
      height: 24px;
      width: 28px;
      content: "";
      display: inline-block;
      float: right;
      background: url(../images/icon-cart.png) no-repeat;
      background-size: contain;
    }
  }
  .content {
    background-color: #e4e3d9;
    background-image: url(../images/shop-online.png);
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: cover;
    padding-top: 15px;
    padding-bottom: 235px;

    a.link-cdbaby {
      display: block;
      text-align: center;
    }
  }
}
.i18n-iu {
  .block-shop {
    h2 {
      font-size: 20px;
    }
  }
}
.i18n-fr {
  .block-shop {
    h2 {
      font-size: 24px;
    }
  }
}

#block-views-partners-block {
  border-right: 1px solid #d2ceb3;
  @include span-columns(4);
  @include media($mobile) {
    border-right: none;
    @include span-columns(2);
  }
  h2 {
    text-transform: uppercase;
    color: #128abc;
  }

}
#block-views-sponsors-block {
  padding-left: 40px;
  @include span-columns(8);
  @include media($mobile) {
    padding-left: 0;
    @include span-columns(2);
  }
  h2 {
    text-transform: uppercase;
    color: #128abc;
  }
}

.block-side-menu {
  background-color: #f1da64;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 25px;

  .content {
    ul.menu {
      margin: 0;
      padding-left: 0;

      li {
        margin-left: 0;
        list-style: none;
        min-width: auto;
      }
      li.last {
        a {
          border-color: transparent;
        }
      }
      .li-level {
        padding: 0;
      }
      .link-level {
        border-bottom-color: #e7ca37;
        font-size: 16px;

        &.active, &:hover, &:focus {
          background-color: #d4b726;
          color: #FFF;
        }
      }
    }
  }
}


.block-videos-intro {
  margin-bottom: 25px;
}
