.node-album {
  .content {
    .field-name-field-album-date {
      margin-bottom: 10px;
    }
    .field-name-field-album-images {
      > .field-items {
        //@include outer-container;

        > .field-item {
          @include span-columns(3);
          @include omega(4n);

          > .field-collection-view {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}

.node-video-production {
  .content {
    .left {
      height: 0;
      overflow: hidden;
      // padding-bottom: 56.25%; // For ratio 16:9. 75% if ratio is 4:3
      padding-bottom: 47.25%;
      position: relative;
      margin-bottom: 20px;
      max-width: 640px;
      object {
        @include position(absolute, 0 null null 0);
        @include size(100%);
      }
    }
  }

  .field-name-field-prod-year {
    span.date-display-single {
      margin-bottom: 0;
    }
  }
}
