

body {
  margin: 0;
  padding: 0;
  background-color: #f5f7ee;
}

body.not-front {
  background-color: #f9f9f9;
  main {
    background-color: #FFF;
  }
}
.outer-wrapper {
  @include outer-container;
}


body.has-no-sidebar {
  main {
    background: #FFF;
    padding-top: 20px;
    padding-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.internal-banner {
  position: relative;
  height: 250px;
  background-image: url(../images/artcirq.jpg);
  background-size: cover;
  background-position-x: center;
  @include media($mobile) {
    padding-left: 0;
    padding-right: 0;
  }
}

.section-who-we-are {
  .internal-banner {
    background-image: url(../images/section-who-we-are.jpg);
  }
}
.section-our-work {
  .internal-banner {
    background-image: url(../images/section-our-work.jpg);
  }
}
.section-media {
  .internal-banner {
    background-image: url(../images/section-media-3.jpg);
  }
}

section#title-wrapper {
  background-image: url(../images/arctic.jpg);
  background-size: cover;
  padding: 70px 0;
  #page-title {
    text-align: center;
    max-width: 80%;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    color: #ac9933;
  }
}

section#hero {
  @include media($mobile) {
    padding-left: 0;
    padding-right: 0;
  }
}

#section-1 {
  background: #f5f7ee;
}
#featured {
  background-color: #f5f7ee;
  background-image: url(../images/snow-white.jpg);
  .outer-wrapper {
  }
}
#content-bottom {
  background-image: url(../images/snow-blue.jpg);
  background-color: #128abc;

  .region-content-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    background: url(../images/map.png) no-repeat;
    background-position-x: right;
    @extend .outer-wrapper;
  }
}
