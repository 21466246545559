//@import url(../fonts/NeutraDisp-Titling/stylesheet.css);
header#header {
  background-color: #128abc;
  background-image: url(../images/igloo-banner.png);
  background-size: cover;

  .outer-wrapper {
    @include media($mobile) {
      padding: 0;
    }
  }

  .branding {
    margin-top: -25px;
    float: left;
    margin-left: 50px;
    position: absolute;
    z-index: 100;
    margin-top: -35px;

    @include media($mobile) {
      position: inherit;
      margin-top: 0;
      margin-left: 0;
      float: none;
      padding: 0 15px;
    }
    > a {
      display: inline-block;
      float: left;
      margin-bottom: 5px;
    }

    .site-name {
      ///font-family: 'Neutra Display Titling';
      font-weight: 900;
      text-transform: uppercase;
      //font-weight: normal !important;
      margin-top: 18px;
      margin-left: 20px;
      display: inline-block;
      color: #fff;
      font-weight: bold;
      font-size: 28px;
      span {
        display: block;
        font-size: 16px;
        text-transform: none;
        font-family: $body_font;
        letter-spacing: normal;
        color: #f1da64;
        line-height: 18px;
        max-width: 200px;
      }
    }
  }
  #navigation {
    float: right;
    @include media($tablet) {
      float: none;
    }
    @include media($mobile) {
      float: none;
    }
  }
}


#top {
  //background: #0b3746;
  .region {
    @include outer-container;
  }
}
