.date-display-single {
  color: #8e8e8e;
}
ul.pager {
  margin-top: 50px;
  @extends .clearfix;
  li {
    margin-left: 0;
    padding: 5px 10px;
    border-radius: 4px;
    a {
      display: inline-block;
      cursor: pointer;
    }
  }

  li:not(.pager-current) {
    background: #e7e5e5;
  }
  li:not(.pager-current):hover {
    background: #d7d7d7;
  }
}
