$link_font: $heading_font;

.li-level-1 {
  float: left;

}

.li-level-2 {
  padding: 0;
  margin: 0;
  min-width: 250px;
  > ul.menu {
    padding-left: 10px;
  }
}
.li-level-3 {
  padding: 0;
  margin: 0;
}

.link-level-1 {
  font-family: $link_font;
  font-weight: 900;
  color: #2b2b2b;
  font-size: 20px;
  text-transform: uppercase;
  padding: 21px 15px;
  display: block;
  letter-spacing: 0.05em;

  cursor: pointer;
  height: 100%;

  &:hover, &.active {
    color: #2b2b2b;
  }
}

.i18n-en {
  .link-level-1 {
    padding: 21px;
  }
}
.i18n-fr {
  .link-level-1 {
    padding: 21px;
  }
}
.i18n-iu {
  .link-level-1 {
    padding: 21px;
  }
}

.link-level-2 {
  font-family: $body_font;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid rgba(255,255,255,0.5);
  padding: 10px 20px;
  display: block;
}
.link-level-3 {
  font-family: $body_font;
  font-size: 16px;
  border-bottom: 1px solid rgba(255,255,255,0.5);
  padding: 10px 20px;
  display: block;
}


////////////////////////
.responsive-menus:not(.responsified) {
  ul.responsive-menus-simple {
    padding-right: 40px;
    margin-top: 0;
    margin-bottom: 0;
    @include clearfix;
    li {
      list-style: none;
    }
    li.li-level {
      padding: 0;
    }
    li.li-level-1 {
      > span {
        @extend .link-level-1;
      }
      > ul {
        opacity: 0;
        position: absolute;
        padding: 0;
        //margin-top: 5px;
        z-index: -1;
        box-shadow: 0 4px 6px 1px rgba(3, 3, 3, 0.13);
        border: solid 1px #e9eae0;
        padding-top: 10px;
        padding-bottom: 10px;
        //@include transform(translateY(20px));

        @include background(linear-gradient(to top,#FFF 4%, #e9eae0 40%));
        @include transition(all 0.2s ease-in-out);

      }
    }
    li.li-level-1:hover {
      > ul {
        z-index: 9999;
        opacity: 1;
        //@include transform(translateY(0));
      }

    }
  }
}

////////////////////////////
// Responsified
////////////////////////////
.responsive-menus.responsified {
  span.toggler {
    border-radius: 0;
    box-shadow: none;
    text-align: right;
    padding: 10px;
    @include media($tablet) {
      float: right;
      margin-top: -40px;
      margin-bottom: 10px;
      margin-right: 10px;
      border-bottom: none !important;
    }
    @include media($mobile) {
      float: right;
      margin-bottom: 10px;
      margin-right: 10px;
      border-bottom: none !important;
    }
  }
  .responsive-menus-simple {
    box-shadow: none;
    @include media($tablet) {
      margin: 5px 0!important;
    }
    @include media($mobile) {
      margin: 50px 0!important;
    }
    .link-level-1 {
      padding: 10px;
      min-height: auto;
      border-bottom: 1px solid rgba(255,255,255,0.5) !important;
      color: white;
    }
    .link-level-1:before, .link-level-1:after {
      height: 0;
    }

    .link-level-2 {
      padding: 10px 15px;
      color: #FFF;


    }

    .link-level-3 {
      padding: 10px 40px 10px 15px;
    }
  }

}

.block-main-menu {
  .menu-name-menu-en-main-menu,
  .menu-name-menu-fr-main-menu,
  .menu-name-menu-iu-main-menu {
    background: #f1da64;
    position: relative;
    height: 63px;

    @include media($tablet) {
      background: transparent;
    }
    @include media($mobile) {
      background: transparent;
      height: auto;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -63px;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 63px 63px;
      border-color: transparent transparent #f1da64 transparent;

      @include media($mobile) {
        display: none;
      }
    }
  }
}
